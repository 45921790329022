import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import shipping from "../../redux/shipping";
import * as config from "../../config";
import TabMenu from "../common/TabMenu";
import { STATUS, TYPE_OF_REPORT_ATTACHMENTS } from "../../../src/constants";
import deleteIcon from "../../assests/images/deleteIcon.svg";
import ActionConfirmModal from "../common/ActionConfirmModal";
import ErrorMessage from "../common/ErrorMessage";
import { FILE_SIZE_LIMIT } from "../../../src/constants";

const ProcessingDocumentation = (shippingDetails) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();
    const { instance } = useMsal();

    const userId = instance.getActiveAccount().name;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [uploadFlag, setUploadFlag] = useState("");
    const [existingdocuments, setExistingDocuments] = useState([]);
    const [showDuplicateConfirmation, setShowDuplicateConfirmation] = useState(false);
    const [isNewFileUploadedAgain, setNewFileUploadedAgain] = useState(false);
    // const [isDocumentsCanUpload, setDocumentsCanUpload] = useState(shippingDetails.redirectTo === `/process/shipping` ? true : false);
    const [isDocumentsCanUpload, setDocumentsCanUpload] = useState(true);
    const [errorMsg, setErrorMsg] = useState([])

    useEffect(() => {
        const totalSize = documents.reduce((acc, file) => Number(acc) + Number(file.size), 0);
        let message = []
        if (totalSize >= FILE_SIZE_LIMIT) {
            message.push(t("file_manager.file_limit_message"))
            setErrorMsg([...message])
        }
        else {
            setErrorMsg([])
        }
    }, [documents])

    useEffect(async () => {
        if (
            shippingDetails.shippingDetails.length !== 0 &&
            shippingDetails.shippingDetails[0].id
        ) {
            const reportType = TYPE_OF_REPORT_ATTACHMENTS.ProcessingDocumentation;
            const documentInput = {
                id: shippingDetails.shippingDetails[0].id,
                reportType: reportType,
            }
            let data = await shippingDetails.actions.getReportsAttachments(
                documentInput
            );
            setExistingDocuments(data.data);
        }
        setIsLoading(false);
    }, []);

    const encodeFile = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFileSelect = async (e) => {
        setSelectedFiles([]);
        setUploadFlag("upload");
        const newFiles = [];
        let fileData;

        if (e.target.files.length !== 0) {
            for (const file of e.target.files) {
                const existingDuplicateFile = existingdocuments.length > 0 && existingdocuments?.find((doc) => doc.file_name === file.name)
                const existingDocment = documents.length > 0 && documents?.find((doc) => doc.name === file.name)
                if (existingDocment) {
                    fileData = {
                        name: file.name,
                        encodedData: await encodeFile(file),
                        id: false,
                        size: file.size,
                    };
                    newFiles.push(fileData);
                    setNewFileUploadedAgain(true);
                }
                else if (existingDuplicateFile) {
                    fileData = {
                        name: file.name,
                        encodedData: await encodeFile(file),
                        id: existingDuplicateFile.id,
                        size: file.size,
                    };
                    newFiles.push(fileData);
                }
                else if (
                    file.type === "application/pdf" ||
                    file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    file.type === "application/x-zip-compressed" ||
                    file.type === "image/png" ||
                    file.type === "image/jpeg" ||
                    file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    file.type === "application/msword"
                ) {
                    fileData = {
                        name: file.name,
                        encodedData: await encodeFile(file),
                        id: false,
                        size: file.size,
                    };
                    newFiles.push(fileData);
                } else {
                    setUploadFlag("");
                    document.getElementById("fileUpload").value = null;
                    toast.error(t("reports.reports_error_msg"));
                }
            }
            setNewFileUploadedAgain(false)
            setSelectedFiles([...newFiles]);
        }
        else {
            setUploadFlag("");
            document.getElementById("fileUpload").value = null;
        }
    };
    const handleDuplicateSaveConfirmation = (isConfirmed) => {
        if (isConfirmed) {
            const shippingidValue = shippingDetails.shippingDetails[0].id;
            const userName = userId;
            const reportType = TYPE_OF_REPORT_ATTACHMENTS.ProcessingDocumentation;

            if (isNewFileUploadedAgain) {
                const newState = documents.map(obj => {
                    const matchedFile = selectedFiles.find(file => file.name === obj.name);
                    if (matchedFile) {
                        return { ...obj, name: matchedFile.name, encodedData: matchedFile.encodedData, size: matchedFile.size };
                    }
                    return obj;
                });
                setDocuments(newState);
                setNewFileUploadedAgain(false);
            }
            else {
                const updatedFiles = selectedFiles?.map(file => ({
                    ...file, shippingidValue, userName, reportType
                }))
                setDocuments([
                    ...documents,
                    ...updatedFiles,
                ]);
            }
        }
        setSelectedFiles([])
        setNewFileUploadedAgain(false);
        setUploadFlag("");
        document.getElementById("fileUpload").value = null;
        setShowDuplicateConfirmation(false);
    };

    const UploadAttachments = async () => {
        let duplicateRecord = false;
        for (const file of selectedFiles) {
            if ((file?.id) || isNewFileUploadedAgain) {
                setShowDuplicateConfirmation(true);
                duplicateRecord = true
                break;
            }
        }
        if (!duplicateRecord) {
            try {
                const shippingidValue = shippingDetails?.shippingDetails[0]?.id;
                const userName = userId;
                const reportType = TYPE_OF_REPORT_ATTACHMENTS.ProcessingDocumentation;
                const updatedFiles = selectedFiles?.map(file => ({
                    ...file, shippingidValue, userName, reportType
                }))            
                setDocuments([
                    ...documents,
                   ...updatedFiles,
                ]);

                setSelectedFiles([]);
                setUploadFlag("");
                document.getElementById("fileUpload").value = null;
            } catch (e) {
                console.error(e);
            }
        }
    };

    const removeFile = async (index) => {
        const filteredFiles = documents.filter((file, index2) => index2 !== index);
        setDocuments(filteredFiles);
    };

    const removeFileForExistingAttachments = async (index, id, reportType) => {
        setIsLoading(true);
        const documentInput = {
            id: id,
            reportType: reportType,
        }
        let data = await shippingDetails.actions.deleteReportsAttachments(documentInput);
        if (data.data.IsSuccess === true) {
            const filteredFiles = existingdocuments.filter(
                (file, index2) => index2 !== index,
            );
            setExistingDocuments(filteredFiles);
            setIsLoading(false);
            toast.success(t("commonlabels.deleteSuccessfullyMsg"));
        } else {
            setIsLoading(false);
            toast.error("Something went wrong.");
        }
    };

    const saveReports = async () => {
        if(errorMsg.length === 0){
        setIsLoading(true);
        shippingDetails.actions.saveReportsAttachments(documents).then(async () => {
            setIsLoading(false);
            setDocuments([]);
            setExistingDocuments([]);
            toast.success(t("commonlabels.savedSuccessfullyMsg"));

            const reportType = TYPE_OF_REPORT_ATTACHMENTS.ProcessingDocumentation;
            const documentInput = {
                id: shippingDetails.shippingDetails[0].id,
                reportType: reportType,
            };
            let data = await shippingDetails.actions.getReportsAttachments(documentInput);
            setExistingDocuments(data.data);
        });
    }
    };

    const cancel = () => {
        history.push({
            pathname: shippingDetails.redirectTo,
        });
    };

    const closeErrorMessage = () => {
        setErrorMsg([]);
    };

    return (
        <>
            <LoadingOverlay active={isLoading} spinner>
                <TabMenu />
                <div className="container-fluid">
                    <div className="mr2"></div>
                    {errorMsg.length > 0 && (
                        <div className="pt0 mt0">
                            <ErrorMessage
                                closeErrorMessage={closeErrorMessage}
                                errorMessages={errorMsg}
                            />
                        </div>
                    )}
                    <div>
                        <div className="row ml15 mr15 mlr15 border">
                            <div className="row col-12 pt12">
                                <><div className="col-12 heading_box">
                                    {t("reports.processing_documentation_header")}{" "}
                                </div>
                                    <div className="row col-md-12 pl-0 pr-0">
                                        {isDocumentsCanUpload && (
                                            <>
                                                <div className="col-md-3 pt10 ml">
                                                    <input
                                                        type="file"
                                                        id="fileUpload"
                                                        multiple={true}
                                                        onChange={(e) => handleFileSelect(e)}
                                                        accept=".xlsx,.pdf,.zip,.jpeg,.doc,.docx" />
                                                </div>
                                                <div className="col-md-3 pt10">
                                                    <button
                                                        className="btn btn-primary new_button"
                                                        onClick={UploadAttachments}
                                                        disabled={uploadFlag === ""}
                                                    >
                                                        {t("dashboard.FileUploadtitle")}
                                                    </button>
                                                    <div className="fileSizeLimitNote">{t("file_manager.file_size_limit_note")}</div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            </div>
                            <div className="col-md-12 pt10">
                                <div className="row pl-0">
                                    <>
                                        {documents.length === 0 && existingdocuments?.length === 0 ? (
                                            <table id="" className="editmaterialtable">
                                                {" "}
                                                {isDocumentsCanUpload && (shippingDetails?.shippingDetails[0]?.status === STATUS.INBOX ||
                                                    shippingDetails?.shippingDetails[0]?.status === STATUS.CANCELLED ||
                                                    shippingDetails?.shippingDetails[0]?.status === STATUS.READY_FOR_DELIVERY ||
                                                    shippingDetails?.shippingDetails[0]?.status === STATUS.INPUT ||
                                                    shippingDetails?.shippingDetails[0]?.status === STATUS.AWAITING_FOR_APPROVAL ||
                                                    shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_1 ||
                                                    shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_2) ? (
                                                    <tr>
                                                        <th>{t("reports.file_name")}</th>
                                                        <th>{t("shipping_request.delete")}</th>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <th>{t("reports.file_name")}</th>
                                                    </tr>
                                                )}
                                                <tbody>
                                                    <tr>{t("commonlabels.emptyDataSourceMessage")}</tr>
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className="col-md-12">
                                                <div className="row pl-0 mb10">
                                                    <table id="" className="editmaterialtable">
                                                        {isDocumentsCanUpload && (shippingDetails?.shippingDetails[0]?.status === STATUS.INBOX ||
                                                            shippingDetails?.shippingDetails[0]?.status === STATUS.CANCELLED ||
                                                            shippingDetails?.shippingDetails[0]?.status === STATUS.READY_FOR_DELIVERY ||
                                                            shippingDetails?.shippingDetails[0]?.status === STATUS.INPUT ||
                                                            shippingDetails?.shippingDetails[0]?.status === STATUS.AWAITING_FOR_APPROVAL ||
                                                            shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_1 ||
                                                            shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_2) ? (
                                                            <tr>
                                                                <th>{t("reports.file_name")}</th>
                                                                <th>{t("shipping_request.delete")}</th>
                                                            </tr>
                                                        ) : (
                                                            <tr>
                                                                <th>{t("reports.file_name")}</th>
                                                            </tr>
                                                        )}
                                                        {existingdocuments?.map((val, key) => {
                                                            return (
                                                                <>
                                                                    <tbody>
                                                                        <tr key={key}>
                                                                            <td>
                                                                                {
                                                                                    <a
                                                                                        href={
                                                                                            config.baseURL +
                                                                                            "/api/downloadFile/" +
                                                                                            val.id +
                                                                                            "/reports"
                                                                                        }
                                                                                    >
                                                                                        {val.file_name}
                                                                                    </a>
                                                                                }
                                                                            </td>
                                                                            {isDocumentsCanUpload && (shippingDetails?.shippingDetails[0]?.status === STATUS.INBOX ||
                                                                                shippingDetails?.shippingDetails[0]?.status === STATUS.CANCELLED ||
                                                                                shippingDetails?.shippingDetails[0]?.status === STATUS.READY_FOR_DELIVERY ||
                                                                                shippingDetails?.shippingDetails[0]?.status === STATUS.INPUT ||
                                                                                shippingDetails?.shippingDetails[0]?.status === STATUS.AWAITING_FOR_APPROVAL ||
                                                                                shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_1 ||
                                                                                shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_2) ? (
                                                                                <td>
                                                                                    <a
                                                                                        id=""
                                                                                        onClick={(e) => {
                                                                                            removeFileForExistingAttachments(key, val.id, TYPE_OF_REPORT_ATTACHMENTS.ProcessingDocumentation);
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            style={{ cursor: "pointer" }}
                                                                                            alt="t"
                                                                                            className="dlt_icon"
                                                                                            src={deleteIcon}
                                                                                        />
                                                                                    </a>
                                                                                </td>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </tr>
                                                                    </tbody>
                                                                </>
                                                            );
                                                        })}
                                                        {documents?.length > 0 && documents?.map((val, key) => {
                                                            return (
                                                                <>
                                                                    <tbody>
                                                                        <tr key={key}>
                                                                            <td>{val.name || val.file_name}</td>
                                                                            <td>
                                                                                <a
                                                                                    id=""
                                                                                    onClick={(e) => {
                                                                                        removeFile(key);
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        style={{ cursor: "pointer" }}
                                                                                        alt="t"
                                                                                        className="dlt_icon"
                                                                                        src={deleteIcon}
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </>
                                                            );
                                                        })}
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row col-12 mb50 pull-right pt10 pl0">
                        <div className="row col-12">
                            {isDocumentsCanUpload && (shippingDetails?.shippingDetails[0]?.status === STATUS.INBOX ||
                                shippingDetails?.shippingDetails[0]?.status === STATUS.CANCELLED ||
                                shippingDetails?.shippingDetails[0]?.status === STATUS.READY_FOR_DELIVERY ||
                                shippingDetails?.shippingDetails[0]?.status === STATUS.INPUT ||
                                shippingDetails?.shippingDetails[0]?.status === STATUS.AWAITING_FOR_APPROVAL ||
                                shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_1 ||
                                shippingDetails?.shippingDetails[0]?.status === STATUS.REGULATORY_REVIEWER_2) ? (
                                <button
                                    className="btn btn-primary new_button"
                                    disabled={documents.length === 0}
                                    onClick={saveReports}
                                >
                                    {t("materials.save")}
                                </button>
                            ) : (
                                ""
                            )}
                            &nbsp;
                            <button
                                className="btn btn-secondary cancelbutton"
                                onClick={cancel}
                            >
                                {t("materials.cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
            <ActionConfirmModal
                show={showDuplicateConfirmation}
                headerText={t("reports.duplicate_report_found")}
                bodyText={t("reports.duplicate_confirmation_message")}
                confirmButtonText={t("commonlabels.yesButton")}
                cancelButtonText={t("commonlabels.noButton")}
                onConfirm={() => handleDuplicateSaveConfirmation(true)}
                onCancel={() => handleDuplicateSaveConfirmation(false)}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    redirectTo: state[shipping.name].redirectTo,
    shippingDetails: state[shipping.name].shippingRequestDetails,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessingDocumentation);
