// general information error messages
export const Messages = "Error or success messages";

export const ROLES = {
  PROCESS_MANAGER: "processmanager",
  ADMIN: "admin",
  APPLICANT: "applicant",
  ALL: "all",
};

//Enum for User Role Start//
export const USERROLES = {
  PROCESSMANAGER: 1,
  ADMIN: 2,
  APPLICANT: 3,
  REGULATORYREVIEWER1: 4,
  REGULATORYREVIEWER2: 5,
  SYSTEMADMINISTRATOR: 6,
  READONLY: 7,
};

export const STATUS = {
   CANCELLED: "CANCELLED",
  IN_DELIVERY: "IN_DELIVERY",
  
  READY_FOR_DELIVERY: "READY_FOR_DELIVERY",
  INPUT: "INPUT",
  INBOX: "INBOX",
  
  REGULATORY_REVIEWER_1: "Regulatory Reviewer 1",
  REGULATORY_REVIEWER_2: "Regulatory Reviewer 2",

  AWAITING_FOR_APPROVAL: "AWAITING_FOR_APPROVAL"
};



// actions
export const INSPECT = "INSPECT";
export const EDIT = "EDIT";
export const SUBMIT = "SUBMIT";
export const DELETE = "DELETE";
export const COPY = "COPY";
export const ORDER_PICKUP = "ORDER_PICKUP";
export const ORDER_CONFIRM = "ORDER_CONFIRM";
export const HAND_BACK = "HAND_BACK";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const NEW = "NEW";
export const CLOSE = "CLOSE";

//roles
export const ADMIN = "ADMIN";
export const APPLICANT = "APPLICANT";
export const PROCESS_MANAGER = "PROCESS_MANAGER";

export const INCOTERMS = [
  { id: "CIP", name: "CIP | Carriage and Insurance Paid To" },
  { id: "CPT", name: "CPT | Carriage Paid To" },
  { id: "DAP", name: "DAP | Delivered at Place" },
  { id: "DDP", name: "DDP | Delivered Duty Paid" },
  { id: "DPU", name: "DPU | Delivered at Place Unloaded" },
  { id: "EXW", name: "EXW | Ex Works" },
  { id: "FCA", name: "FCA | Free Carrier" },
];

export const TYPE_OF_SHIPMENT = [
  { id: "DOMESTIC", name: "Domestic" },
  { id: "INTERNATIONAL", name: "International" },
  { id: "INNER_EU", name: "Within EU" },
];

// #4622 - Data is pushed to the table - public.master_logistics_partner

export const IMPORTER_OF_RECORDS = [
  { id: "ELANCO", name: "Elanco" },
  { id: "THIRD_PARTY", name: "Third party" },
  { id: "UNKNOWN", name: "Unknown" },
];

export const NAV_KEYS = {
  NEW_SHIPPING_REQUEST: "NEW_SHIPPING_REQUEST",
  MY_SHIPPING_REQUESTS: "MY_SHIPPING_REQUESTS",
  REQUESTS_BY_DELEGATE: "REQUESTS_BY_DELEGATE",
  PROCESS_SHIPPING_REQUESTS: "PROCESS_SHIPPING_REQUESTS",

  APPROVALS: "APPROVALS",
  APPROVALS_ADDRESS: "APPROVALS_ADDRESS",
  APPROVALS_MATERIALS: "APPROVALS_MATERIALS",
  APPROVALS_RECIPIENTS: "APPROVALS_RECIPIENTS",

  ADMINISTRATION_ADDRESS: "ADMINISTRATION_ADDRESS",
  ADMINISTRATION_RECIPIENT: "ADMINISTRATION_RECIPIENT",
  ADMINISTRATION_MATERIAL: "ADMINISTRATION_MATERIAL",
  ADMINISTRATION_SHIPPING_REQUEST: "ADMINISTRATION_SHIPPING_REQUEST",
  ADMINISTRATION_DISTRIBUTION_TEMPERATURE:
    "ADMINISTRATION_DISTRIBUTION_TEMPERATURE",
  ADMINISTRATION_PHASE: "ADMINISTRATION_PHASE",
  ADMINISTRATION_TITLE: "ADMINISTRATION_TITLE",
  ADMINISTRATION_CONTAINER: "ADMINISTRATION_CONTAINER",
  ADMINISTRATION_UNITS: "NAV_KEYS.ADMINISTRATION_UNITS",
  ADMINISTRATION_CURRENCY: "ADMINISTRATION_CURRENCY",
  ADMINISTRATION_ADD_COUNTRY: 'ADMINISTRATION_ADD_COUNTRY',
  ADMINISTRATION_BROKER: "ADMINISTRATION_BROKER",
  ADMINISTRATION_SHIPPING_TYPE: "ADMINISTRATION_SHIPPING_TYPE",
  ADMINISTRATION_INCOTERM: "ADMINISTRATION_INCOTERM",
  ADMINISTRATION_IMPOTER: "ADMINISTRATION_IMPOTER",
  ADMINISTRATION_CONTRY_CATALOGUE: "ADMINISTRATION_CONTRY_CATALOGUE",
  ADMINISTRATION_REGISTURED_USER: "ADMINISTRATION_REGISTURED_USER",
  ADMINISTRATION_LOGISTICS_PARTNER: "ADMINISTRATION_LOGISTICS_PARTNER",

  QUERIES_COUNTRY: "QUERIES_COUNTRY",
  QUERIES_DELIVEY_COUNTRY: "QUERIES_DELIVEY_COUNTRY",

  MY_APPLICATIONS_ADDRESS: "MY_APPLICATIONS_ADDRESS",
  MY_APPLICATIONS_MATERIALS: "MY_APPLICATIONS_MATERIALS",
  MY_APPLICATIONS_RECIPIENTS: "MY_APPLICATIONS_RECIPIENTS",

  CONTACT: "CONTACT",
  REGULATORY_REVIEWER: "REGULATORY_REVIEWER",

  ALERTS_TAGS: "ALERTS_TAGS"
};

export const REDIRECTION_URL = {
  escParam: "https://thespot.elanco.com/esc?id=sc_cat_item&sys_id=3f1dd0320a0a0b99000a53f7604a2ef9&sysparm_category=null&catalog_id=e0d08b13c3330100c8b837659bba8fb4&referrer=popular_items"
};

export const SHIPPER_TYPES = {
  consignor: "consignor",
  shipper: "shipper",
};

export const INVOICE_TYPES = {
  USA: "USA",
  OTHER: "OTHER",
}; export const WeekDay = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday"
}
export const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const EUCountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK",
  "SI", "ES"]

export const INVOICE_FOOTER_INFO = { Invoice_Footer_Information: "These items are controlled by the U.S. Government and authorized for export only to the country of ultimate destination for use by the ultimate consignee or end-user(s) herein identified. They may not be resold, transferred, or otherwise disposed of, to any other country or to any person other than the authorized ultimate consignee or end-user(s), either in their original form or after being incorporated into other items, without first obtaining approval from the U.S. Government or as otherwise authorized by U.S. Law and regulations. Seller represents that with respect to the production of the articles and/or the performance of the services covered by this invoice it has fully complied with section 12(a) of the fair labor standards act of 1938, as amended." }

export const TYPE_OF_REPORT_ATTACHMENTS = {
  Reports: "R"
  , ShipmentDocumentation: "SD"
  , ProcessingDocumentation: "PD"
};

export const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}

export const FILE_SIZE_LIMIT = 70 * 1024 * 1024;
